.shape-btn {
    background-color: transparent;
    border: 1px solid #ccc;
}

.active {
    background: #fff;
}

.color-picker {
    justify-content: space-evenly;
.color {
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--color);
    box-shadow:var(--color) 0px 0px 0px 17px inset;  
    transition: all 100ms;

&:hover {
    transform: scale(1.1);
}
}
.active {
    box-shadow: #c800ff 0px 0px 1px 3px;
}
}

.color-selection {
    display: flex;
    border-radius: 5px;
    background: #f9f9f9;
    border-radius: 5px;

    button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 5px;
    border-radius: 5px;
    margin: 0;
    color: #020000;
    background: #f9f9f9;
    }
.active {
    background: #ffa459;
    border-radius: 5px;
    color: #fff;
}
}

.shape-container {
    height: 200px;
    overflow-y: auto;
}